export default {
    getTreeData(data) {
        if (!data) return;
        // 循环遍历json数据
        try {
            for (var i = 0; i < data.length; i++) {
                if (data[i].children && data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
        } catch (error) {
            console.log(error)
        }
        return data;
    },
}